import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../layout/footer/footer.component';
import { HeaderComponent } from '../layout/header/header.component';
import { IndexComponent } from './index/index.component';

@Component({
  selector: 'gugudata-master',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
  ],
  templateUrl: './master.component.html',
  styleUrl: './master.component.scss',
})
export class MasterComponent {
  title = 'gugudata-io-portal';
}
