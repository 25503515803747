import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthGoogleService } from '@app/core/services/auth-google.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthCallbackResolver implements Resolve<any> {
  constructor(
    private authGoogleService: AuthGoogleService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      let callbackData = this.authGoogleService.getProfile();
      console.log('callbackData', callbackData);
      if (callbackData) {
        this.authGoogleService.loginCallback(callbackData);
        return of(callbackData);
      } else {
        return of(null);
      }
    }
    return of(null);
  }
}
