import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withFetch,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { Router, provideRouter, withInMemoryScrolling } from '@angular/router';
import { routes } from '@app/app.routes';
import { HttpErrorInterceptor } from '@core/http/base.interceptor';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideHighlightOptions } from 'ngx-highlightjs';
import { provideNgxStripe } from 'ngx-stripe';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withFetch()),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    importProvidersFrom(HttpClientModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    provideClientHydration(),
    provideOAuthClient(),
    provideHighlightOptions({
      fullLibraryLoader: () => import('highlight.js'),
      themePath: 'assets/css/atom-one-dark.min.css',
    }),
    provideNgxStripe(environment.stripe.publishableKey),
  ],
};
