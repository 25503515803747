import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VerifyTokenResolver implements Resolve<any> {
  constructor(private authService: AuthService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const token = route.queryParams['token'];
    return this.authService.verifyToken(token).pipe(
      map((response) => {
        // goto user profile page
        if (response.status === 'SUCCESS') {
          this.router.navigate(['/verify-success']);
          return response;
        }
        // goto error page
        this.router.navigate(['/error']);
      }),
      catchError(() => {
        this.router.navigate(['/error']);
        return of(null);
      })
    );
  }
}
