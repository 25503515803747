import { Routes } from '@angular/router';
import { MasterComponent } from './components/pages/master.component';
import { GoogleAuthCallbackResolver } from './resolver/google-auth-callback';
import { VerifyTokenResolver } from './resolver/verify-token-resolver';

export const routes: Routes = [
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./components/pages/account/sign-up/sign-up-index.component').then(
        (m) => m.SignUpIndexComponent
      ),
  },
  {
    path: 'sign-in',
    loadComponent: () =>
      import('./components/pages/account/sign-in/sign-in-index.component').then(
        (m) => m.SignInIndexComponent
      ),
  },
  {
    path: 'sign-success',
    loadComponent: () =>
      import(
        './components/pages/account/sign-success/sign-success.component'
      ).then((m) => m.SignSuccessComponent),
  },
  {
    path: 'verify-success',
    loadComponent: () =>
      import(
        './components/pages/account/verify-success/verify-success.component'
      ).then((m) => m.VerifySuccessComponent),
  },
  {
    path: 'auth/verify-token',
    resolve: { verifyToken: VerifyTokenResolver },
    loadComponent: () =>
      import('./components/pages/account/sign-in/sign-in-index.component').then(
        (m) => m.SignInIndexComponent
      ),
  },
  {
    path: 'auth/google-auth-callback',
    resolve: { verifyToken: GoogleAuthCallbackResolver },
    loadComponent: () =>
      import('./components/pages/account/sign-in/sign-in-index.component').then(
        (m) => m.SignInIndexComponent
      ),
  },
  {
    path: '',
    children: [
      {
        path: '',
        component: MasterComponent,
        loadChildren: () =>
          import('./components/pages/master.module').then(
            (m) => m.MasterModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '/404' },
];
