/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { injectSpeedInsights } from '@vercel/speed-insights';
import * as packageInfo from '../package.json';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

// only load in prod mode
if (environment.production) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  injectSpeedInsights();
}

bootstrapApplication(AppComponent, appConfig)
  .then(() => {
    (window as any)['__VERSION__'] = packageInfo.version;
  })
  .catch((err) => console.error(err));
